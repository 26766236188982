import React, { useEffect, useState } from "react";
import Header from "../common/components/header/header";
import Navbar from "../common/components/navbar/navbar";
import TableHeader from "../common/components/tableHeader/tableHeader";
import { Radio, Switch, Table } from "antd";
import GeneralPromocode from "./Components/GeneralPromocode/GeneralPromocode";
import PromocodeForGuru from "./Components/PromocodeForGuru/PromocodeForGuru";
import PromocodeForSession from "./Components/PromocodeForSession/PromocodeForSession";
import {
	getPromocodes,
	getSalesPersonData,
	promoCodeToggleStateAndUsage,
} from "./network";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import { Excel } from "antd-table-saveas-excel";
import PromocodeForPackage from "./Components/PromocodeForPackage/PromocodeForPackage";
import PromoCodeForEvent from "./Components/PromoCodeForEvent/PromoCodeForEvent";
function Promocodes() {
	const [value, setValue] = useState(1);
	const [dataSource, updateDataSource] = useState([]);
	const [salesPersonsData, updateSalesPersonsData] = useState([]);
	const [loadingState, updateLoadingState] = useState(false);
	const onRadioChange = (e) => {
		setValue(e.target.value);
	};

	const callGetPromocodes = () => {
		updateLoadingState(true);
		getPromocodes(
			(success) => {
				updateDataSource(success.data);
				updateLoadingState(false);
			},
			(fail) => {
				console.log(fail);
			}
		);
	};

	const promoCodeToggleStateAndUsageFunc = (
		couponId,
		isActive,
		isSingleUsage
	) => {
		let data = {
			couponId,
			isActive,
			isSingleUsage,
		};
		promoCodeToggleStateAndUsage(
			data,
			(success) => {
				if (success.code === 1) {
					callGetPromocodes();
				}
			},
			(fail) => {
				console.log(fail);
			}
		);
	};
	useEffect(() => {
		callGetPromocodes();
		getSalesPersonData(
			(success) => {
				updateSalesPersonsData(success.data);
			},
			(fail) => {}
		);
	}, []);

	const columns = [
		{
			title: "Promocode",
			dataIndex: "coupon",
			key: "coupon",
		},
		{
			title: "Value",
			dataIndex: "value",
			key: "value",
			render: (value) => {
				return <>{value}%</>;
			},
		},
		{
			title: "Type",
			dataIndex: "couponTypeName",
			key: "couponTypeName",
		},
		{
			title: "Client's Matching Text",
			dataIndex: "matchingText",
			key: "matchingText",
		},
		{
			title: "Applied for",
			dataIndex: "appliedFor",
			key: "appliedFor",
			render: (coach, _) => {
				if (_.couponType === 1) return <>For all</>;
				else if (_.couponType === 2) return <>{_.coach[0].fullName}</>;
				else if (_.couponType === 3) {
					return (
						<>
							{_.coach[0].fullName} on {_.sessionDateTime}
						</>
					);
				} else
					return (
						<>
							{_.coach[0].fullName} on package {_.packageName}
						</>
					);
			},
		},
		{
			title: "is Active",
			dataIndex: "isActive",
			key: "isActive",
			render: (isActive, _) => {
				return (
					<div>
						{/* <Button
							danger
						
						>
							Delete
						</Button> */}

						<Switch
							className={"switch"}
							checkedChildren={<CheckOutlined />}
							unCheckedChildren={<CloseOutlined />}
							checked={isActive}
							onChange={(isActive) => {
								const isSingleUsage = _.isSingleUsage ? true : false;
								promoCodeToggleStateAndUsageFunc(
									_._id,
									isActive,
									isSingleUsage
								);
							}}
						/>
					</div>
				);
			},
		},
		{
			title: "is Single use",
			dataIndex: "isSingleUsage",
			key: "isSingleUsage",
			render: (isSingleUsage, _) => {
				return (
					<div>
						<Switch
							className={"switch"}
							checkedChildren={<CheckOutlined />}
							unCheckedChildren={<CloseOutlined />}
							checked={isSingleUsage}
							onChange={() => {
								const isSingleUsage = _.isSingleUsage ? true : false;
								promoCodeToggleStateAndUsageFunc(
									_._id,
									_.isActive,
									!isSingleUsage
								);
							}}
						/>
					</div>
				);
			},
		},
	];
	const handleSaveExcel = () => {
		const excel = new Excel();

		let allCols = [...columns];
		let allData = [...dataSource];
		allCols.forEach((colItem) => {
			delete colItem.render;
		});
		allData.forEach((dataItem, dataIndex) => {
			var appliedFor = "";
			if (dataItem.couponType === 1) appliedFor = "For all";
			else if (dataItem.couponType === 2)
				appliedFor = dataItem.coach[0].fullName;
			else
				appliedFor =
					dataItem.coach[0].fullName + "on" + dataItem.sessionDateTime;
			allData[dataIndex].appliedFor = appliedFor;
		});
		excel
			.addSheet("test")
			.addColumns(allCols)
			.addDataSource(allData)
			.saveAs(`Promocodes.xlsx`);
	};

	return (
		<div>
			<Navbar />
			<Header parent="Promocodes" />
			<TableHeader parent={"Promocodes"} />
			<div className="content">
				<Radio.Group
					onChange={onRadioChange}
					value={value}
					className="d-flex flex-1 justify-content-around my-2 flex-wrap">
					<Radio value={1}>General Promocode</Radio>
					<Radio value={2}>Promocode for a Guru</Radio>
					<Radio value={3}>Promocode for a Session</Radio>
					<Radio value={4}>Promocode for a Package</Radio>
					<Radio value={5}>Promocode for an Event</Radio>
				</Radio.Group>
				{value === 1 ? (
					<GeneralPromocode
						dataSource={dataSource}
						callGetPromocodes={callGetPromocodes}
						salesPersonsData={salesPersonsData}
					/>
				) : value === 2 ? (
					<PromocodeForGuru
						dataSource={dataSource}
						callGetPromocodes={callGetPromocodes}
						salesPersonsData={salesPersonsData}
					/>
				) : value === 3 ? (
					<PromocodeForSession
						dataSource={dataSource}
						callGetPromocodes={callGetPromocodes}
						salesPersonsData={salesPersonsData}
					/>
				) : value === 4 ? (
					<PromocodeForPackage
						dataSource={dataSource}
						callGetPromocodes={callGetPromocodes}
						salesPersonsData={salesPersonsData}
					/>
				) : (
					<PromoCodeForEvent
						dataSource={dataSource}
						callGetPromocodes={callGetPromocodes}
						salesPersonsData={salesPersonsData}
					/>
				)}

				<div className="text-right">
					<button className="secondaryBtn m-4" onClick={handleSaveExcel}>
						Export
					</button>
				</div>
				<Table
					dataSource={dataSource}
					columns={columns}
					scroll={{ x: 1000, y: 400 }}
					pagination={{ position: ["bottomCenter"] }}
					loading={loadingState}
				/>
			</div>
		</div>
	);
}

export default Promocodes;
