import React, { useEffect, useState } from "react";
import { Row, Col, Menu, Dropdown, Input, Button, Space } from "antd";
import Navbar from "../common/components/navbar/navbar";
import Header from "../common/components/header/header";
import Grid from "../common/components/grid/grid";
//import SideMenu from "../common/components/sideMenu/sideMenu";
import SessionModal from "../sessions/components/sessionModal/sessionModal";
import EmptyDropDownArrow from "../../assets/icons/emptyDropDownArrow.svg";
import { getAllSessions, UpdateSessionStatus } from "./network";
import { mediaBaseUrl } from "../../services";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import moment from "moment";
import { isSummerTime } from "../../App";

function Sessions() {
	const [sessions, setSessions] = useState([]);
	const [searchText, setSearchText] = useState("");
	const [searchedColumn, setSearchedColumn] = useState("");
	const [isModalVisible, toggleModal] = useState(false);
	const [record, setRecord] = useState({});
	const [serverTime, updateServerTime] = useState(null);
	const [needsRefresh, updateNeedsRefresh] = useState(false);
	const onClick = ({ key }) => {
		let body = {
			id: record._id,
			response: key,
		};
		UpdateSessionStatus(
			body,
			(success) => {
				if (success.code === 1) {
					getAllSessions(
						(res) => {
							setSessions(res.sessions);
						},
						(err) => console.log(err)
					);
				}
			},
			(fail) => {}
		);
	};

	useEffect(() => {
		getAllSessions(
			(res) => {
				setSessions(res.sessions);
				updateServerTime(res.serverTime);
			},
			(err) => console.log(err)
		);
	}, [needsRefresh]);

	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	};

	const handleReset = (clearFilters) => {
		clearFilters();
		setSearchText("");
	};

	const getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({
			setSelectedKeys,
			selectedKeys,
			confirm,
			clearFilters,
		}) => (
			<div style={{ padding: 8 }}>
				<Input
					ref={(node) => {
						//			searchInput = node;
					}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) =>
						setSelectedKeys(e.target.value ? [e.target.value] : [])
					}
					//value={searchText[0]}
					onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ marginBottom: 8, display: "block" }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
						size="small"
						style={{ width: 90 }}>
						Search
					</Button>
					<Button
						onClick={() => handleReset(clearFilters)}
						size="small"
						style={{ width: 90 }}>
						Reset
					</Button>
				</Space>
			</div>
		),
		onFilter: (value, record) =>
			record[dataIndex]
				? record[dataIndex]
						.toString()
						.toLowerCase()
						.includes(value.toLowerCase())
				: "",
		onFilterDropdownVisibleChange: (visible) => {
			if (visible) {
				//	setTimeout(() => searchInput.select(), 100);
			}
		},
		render: (text) =>
			searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
					searchWords={[searchText]}
					autoEscape
					textToHighlight={text ? text.toString() : ""}
				/>
			) : (
				text
			),
	});

	const menu = (
		<Menu onClick={onClick}>
			<Menu.Item key="Confirmed" id="x">
				Confirmed
			</Menu.Item>
			<Menu.Item key="Cancelled"> Cancelled</Menu.Item>
			<Menu.Item key="Reschedule">Reschedule</Menu.Item>
			<Menu.Item key="No Answer">No Answer</Menu.Item>
			<Menu.Item key="Paid out"> Paid out</Menu.Item>
		</Menu>
	);

	const columns = [
		{
			title: "",
			dataIndex: "profileImg",
			key: "profileImg",
			render: (img, _) => {
				return (
					<div className="avatarContainer">
						<img src={mediaBaseUrl + img[0]} alt="avatar" />
						{/*  Coach image that the session in booked with */}
					</div>
				);
			},
			onCell: (record, rowIndex) => ({
				onClick: () => {
					setRecord(record);
					toggleModal(!isModalVisible);
				},
			}),
		},
		{
			title: "Guru",
			dataIndex: "guru",
			key: "guru",
			sorter: (a, b) => {
				if (a.guru < b.guru) return -1;
				else return 1;
			},
			...getColumnSearchProps("guru"),
			filterIcon: (filtered) => (
				<SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
			),

			onCell: (record, rowIndex) => ({
				onClick: () => {
					setRecord(record);
					toggleModal(!isModalVisible);
				},
			}),
		},
		{
			title: "Client",
			dataIndex: "client",
			key: "client",
			sorter: (a, b) => {
				if (a.client < b.client) return -1;
				else return 1;
			},
			onCell: (record, rowIndex) => ({
				onClick: () => {
					setRecord(record);
					toggleModal(!isModalVisible);
				},
			}),
		},
		{
			title: "Client's Gender",
			dataIndex: "gender ",
			key: "gender ",
			onCell: (record, rowIndex) => ({
				onClick: () => {
					setRecord(record);
					toggleModal(!isModalVisible);
				},
			}),
			render: (gender, _) => {
				return <div>{_.gender.length ? _.gender[0].genderName : ""}</div>;
			},
		},
		{
			title: "Date",
			dataIndex: "date",
			key: "date",
			sorter: (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
			onCell: (record, rowIndex) => ({
				onClick: () => {
					setRecord(record);
					toggleModal(!isModalVisible);
				},
			}),
			render: (date) => {
				return <div>{moment(date).format("DD/MM/YYYY")}</div>;
			},
		},
		{
			title: "Time",
			dataIndex: "time",
			key: "time",
			sorter: (a, b) => {
				if (moment(a.time).valueOf() > moment(b.time).format()) return 1;
				else return -1;
			},
			onCell: (record, rowIndex) => ({
				onClick: () => {
					setRecord(record);
					toggleModal(!isModalVisible);
				},
			}),
			render: (time, _) => {
				let timeVar = moment(time)
					.tz(isSummerTime ? "Africa/Addis_Ababa" : "Africa/Cairo")
					.format("DD-MM-YYYY hh:mm a")
					.toString();
				timeVar += " (Egypt Time)";
				return <>{timeVar} </>;
			},
		},
		{
			title: "Session Type",
			dataIndex: "sessionType",
			key: "sessionType",
			sorter: (a, b) => {
				if (a.sessionType < b.sessionType) return -1;
				else return 1;
			},
			onCell: (record, rowIndex) => ({
				onClick: () => {
					setRecord(record);
					toggleModal(!isModalVisible);
				},
			}),
		},
		{
			title: "Promocode",
			dataIndex: "coupon",
			key: "coupon",
			...getColumnSearchProps("coupon"),
			filterIcon: (filtered) => (
				<SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
			),
			sorter: (a, b) => {
				if (a.coupon < b.coupon) return -1;
				else return 1;
			},
			onCell: (record, rowIndex) => ({
				onClick: () => {
					setRecord(record);
					toggleModal(!isModalVisible);
				},
			}),
		},
		{
			title: "Date Created",
			dataIndex: "createdAt",
			key: "createdAt",
			render: (createdAt) => {
				return (
					<>
						{moment(createdAt)
							.tz(isSummerTime ? "Africa/Addis_Ababa" : "Africa/Cairo")
							.format("DD-MM-YYYY hh:mm a")}{" "}
						(Egypt Time)
					</>
				);
			},
			sorter: (a, b) => {
				if (a.createdAt > b.createdAt) return -1;
				else return 1;
			},
			// defaultSortOrder: "descend",
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			sorter: (a, b) => {
				if (a.status < b.status) return -1;
				else return 1;
			},
			onCell: (record, rowIndex) => ({
				onClick: () => {
					setRecord(record);
					toggleModal(!isModalVisible);
				},
			}),
		},
		{
			title: "Response",
			dataIndex: "response",
			key: "response",
			render: (response) => {
				return (
					<div>
						<span style={{ textDecoration: "underline" }}>{response}</span>
						<Dropdown overlay={menu} placement="bottomRight" id="x">
							<img
								src={EmptyDropDownArrow}
								alt="DropDownArrow"
								style={{ cursor: "pointer", marginLeft: "5px" }}
							/>
						</Dropdown>
					</div>
				);
			},
			// sorter: (a, b) => {
			// 	if (a.response < b.response) return -1;
			// 	else return 1;
			// },
			onCell: (record, rowIndex) => ({
				onClick: () => {
					setRecord(record);
				},
			}),
		},
	];

	return (
		<div>
			<Navbar />
			<Header parent="Sessions" />
			<Row>
				{/* <Col xs={4}>
					<SideMenu current="Sessions" />
				</Col> */}
				<Col xs={24}>
					<Grid
						dataSource={sessions}
						columns={columns}
						parent="Sessions"
						searchValue={(searchValue) => {
							setSearchText([searchValue]);
						}}
					/>
				</Col>
			</Row>
			{isModalVisible && (
				<SessionModal
					isModalVisible={isModalVisible}
					toggleModal={() => toggleModal(!isModalVisible)}
					record={record}
					serverTime={serverTime}
					updateSessions={() => {
						updateNeedsRefresh(!needsRefresh);
					}}
				/>
			)}
		</div>
	);
}

export default Sessions;
