import { Table } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { getSalesPersonData } from "../../network";
import AddSalesPerson from "../AddSalesPerson/AddSalesPerson";
// import { Link } from "react-router-dom";
function SalesGrid() {
	const [salesData, updateSalesData] = useState([]);
	const [mode, updateMode] = useState(null);
	const history = useHistory();
	const location = useLocation();
	useEffect(() => {
		const queryArr = location.search.split("mode=");
		const modeQuery = queryArr.length ? queryArr[1] : null;
		updateMode(modeQuery);

		getSalesPersonData(
			(success) => {
				updateSalesData(success.data);
			},
			(fail) => {}
		);
	}, [location]);

	const columns = [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			align: "center",
		},
		{
			title: "Promocodes",
			dataIndex: "promoCodes",
			key: "promoCodes",
			align: "center",
			render: (promoCodes) => {
				return (
					<>
						{promoCodes.map((code) => {
							return (
								<div>
									{code.coupon} used {code.count}
								</div>
							);
						})}
					</>
				);
			},
		},
	];
	const handleAddNewSalesPerson = () => {
		history.push("/sales?mode=add");
	};
	return (
		<div>
			{mode ? (
				<AddSalesPerson />
			) : (
				<>
					<div className="text-right">
						<button
							className="secondaryBtn m-4"
							onClick={handleAddNewSalesPerson}>
							Add
						</button>
					</div>
					<Table dataSource={salesData} columns={columns} parent="Client" />
				</>
			)}
		</div>
	);
}

export default SalesGrid;
