import React, { useEffect, useState } from "react";
import { Modal, Button } from "antd";
import {
	AddSessionDiscount,
	bookSession,
	checkValidBook,
	NotifyChangeSchedule,
} from "../../network";
import { getAllUsers } from "../../../clients/network";
import SelectSearch, { fuzzySearch } from "react-select-search";
import moment from "moment";
import TimezoneSelect, { allTimezones } from "react-timezone-select";
import { toastError } from "../../../../helpers/toast";
import "./DiscountModal.css";

function DiscountModal({
	isDiscountModalVisible,
	onCancel,
	selectedTimeSlot,
	record,
	currentSchedules,
	updateCurrentSchedules,
	schedules,
	updateSchedules,
}) {
	const [discount, updateDiscount] = useState(selectedTimeSlot.adminDiscount);
	const [clientId, updateClientId] = useState(null);
	const [clientsList, updateclientsList] = useState([]);
	const [selectedTimezone, setSelectedTimezone] = useState("");
	useEffect(() => {
		let data = [];
		getAllUsers(
			(success) => {
				success.users.forEach((user) => {
					data.push({
						name: `${user.clientName} (${user.email})`,
						value: user._id,
					});
				});
				updateclientsList(data);
			},
			(fail) => {
				console.log(fail);
			}
		);
	}, []);
	const submitDiscount = () => {
		let data = {
			timeSlotId: selectedTimeSlot._id,
			discountPercentage: parseInt(discount),
		};
		AddSessionDiscount(data, (success) => {
			if (success.status === 1) {
				onCancel();
			}
		});
	};
	const bookTheSession = () => {
		let data = {
			sessionTopic: "",
			coachId: record._id,
			clientId,
			anonymous: false,
			price: record.pricePerHour,
			userType: 1,
			paymentNumber: 0, //payment number
			coachTimeId: selectedTimeSlot._id,
			timeZone: selectedTimezone?.value,
			applyFreeSession: false,
			applyCoupon: false,
			periodMin: selectedTimeSlot.period === 30 ? 30 : 60,
			period: selectedTimeSlot.period === 30 ? 0 : 1,
		};
		bookSession(
			data,
			(success) => {
				NotifyChangeSchedule(
					{},
					(success) => {
						onCancel();
					},
					(fail) => {}
				);
			},
			(fail) => {
				toastError(
					fail.response.data.message?.message
						? fail.response.data.message?.message
						: fail.response.data.message
				);
			}
		);
	};
	const checkIsValidBook = () => {
		let data = {
			coachId: record._id,
			clientId,
			fullDate: selectedTimeSlot.startDateTime,
			endDate: selectedTimeSlot.endDateTime,
		};
		checkValidBook(
			data,
			(success) => {
				bookTheSession();
			},
			(fail) => {
				toastError(fail.response.data.message);
			}
		);
	};
	const handleBook = () => {
		if (clientId && record && selectedTimeSlot) {
			checkIsValidBook();
		} else {
			alert("Please select a client to book a session");
		}
	};

	const handleDeleteSession = () => {
		currentSchedules.forEach((currentSchedule, index) => {
			if (currentSchedule.length) {
				const filteredSchedules = currentSchedule.filter(
					(session) => session._id !== selectedTimeSlot._id
				);
				currentSchedules[index] = filteredSchedules;
			}
		});
		schedules.forEach((schedule, index) => {
			const filteredTimes = schedule.times.filter(
				(time) => time._id !== selectedTimeSlot._id
			);
			schedules[index].times = filteredTimes;
		});
		updateSchedules(schedules);
		updateCurrentSchedules(currentSchedules);
		onCancel();
	};
	return (
		<Modal
			visible={isDiscountModalVisible}
			onCancel={onCancel}
			footer={[]}
			width={"50%"}
			centered
			className="discountModal">
			{selectedTimeSlot && (
				<>
					<h5>
						{moment(selectedTimeSlot.startDateTime).format(
							"MMMM Do, YYYY hh:mm"
						)}
					</h5>
					<div>
						<h5>Client</h5>
						<div className="d-flex align-items-center flex-column flex-1">
							<SelectSearch
								options={clientsList}
								search={true}
								value={clientId}
								placeholder="Select a client"
								filterOptions={fuzzySearch}
								onChange={(clientId) => {
									updateClientId(clientId);
								}}
							/>
							<div className="d-flex mt-2">
								<TimezoneSelect
									style={{ width: "100%" }}
									value={selectedTimezone}
									onChange={setSelectedTimezone}
									timezones={{
										...allTimezones,
										"Africa/Addis_Ababa": "Cairo Summer Time",
									}}
								/>
								<div>
									<Button
										className="secondaryBtn mx-2 book_btn"
										onClick={handleBook}
										disabled={!(selectedTimezone && clientId)}>
										Book
									</Button>
								</div>
							</div>
						</div>
					</div>
					<div>
						<h5>Discounts %</h5>
						<div className="d-flex">
							<input
								type="number"
								className="form-control text-center"
								placeholder="Discount Percentage"
								value={discount}
								onChange={(e) => {
									updateDiscount(
										e.target.value > 100
											? 100
											: e.target.value < 0
											? 0
											: e.target.value
									);
								}}
								min="0"
								max="100"
							/>
							<Button className="secondaryBtn mx-2" onClick={submitDiscount}>
								Confirm
							</Button>
						</div>
					</div>
				</>
			)}
			<div className="text-center mt-4">
				<Button
					type="primary"
					className="secondaryBtn"
					onClick={handleDeleteSession}>
					Delete Session
				</Button>
			</div>
		</Modal>
	);
}

export default DiscountModal;
