import React, { useState, useEffect } from "react";
import { Button, Input, Select } from "antd";
import SelectSearch, { fuzzySearch } from "react-select-search";
import { getAllUsers } from "../../../gurus/network";
import { createPromocode } from "../../network";
import { toastError } from "../../../../helpers/toast";
import "./PromocodeForPackage.css";
function PromocodeForPackage({
	callGetPromocodes,
	dataSource,
	salesPersonsData,
}) {
	const [percent, updatePercent] = useState(null);
	const [guruId, updateGuruId] = useState(null);
	const [options, updateOptions] = useState([]);
	const [gurusList, updateGurusList] = useState([]);
	const [packagesList, updatePackagesList] = useState([]);
	const [packageId, updatePackageId] = useState(null);
	const [promocode, updatePromocode] = useState(null);
	const [selectedSalesPerson, updateSelectedSalesPerson] = useState(null);
	const { Option } = Select;

	function handlePackageChange(value) {
		updatePackageId(value);
	}
	useEffect(() => {
		let data = [];

		getAllUsers(
			(success) => {
				updateGurusList(success.users);
				success.users.forEach((user) => {
					data.push({
						name: user.fullName,
						value: user._id,
					});
				});
				updateOptions(data);
			},
			(fail) => {
				console.log(fail);
			}
		);
	}, []);
	const handleGetPackages = (guruId) => {
		const guruDetails = gurusList.filter((guru) => guru._id === guruId);
		updatePackagesList(guruDetails[0].packages);
	};
	const handleGeneratePromocode = () => {
		const isNotUniquePromocode = dataSource.some(
			(promoCode) => promoCode.coupon === promocode
		);
		if (isNotUniquePromocode) {
			toastError("Promocode is used before!");
		} else {
			if (percent && packageId) {
				if (percent) {
					if (percent >= 90 && percent <= 99) {
						toastError("Percentage between 90 and 99 are not allowed!");
					} else {
						let data = {
							discountPercentage: percent > 100 ? 100 : percent,
							couponType: 4,
							coachId: guruId,
							packageId: packageId,
							promocode,
							isSingleUsage: false,
							salesPersonId: selectedSalesPerson,
						};
						createPromocode(
							data,
							(success) => {
								if (success.code === 1) callGetPromocodes();
							},
							(fail) => {
								console.log(fail);
							}
						);
					}
				}
			} else {
				toastError("Please provide a valid Percent or select a Package!");
			}
		}
	};

	const handleChangeSalesPerson = (salesPersonId) => {
		updateSelectedSalesPerson(salesPersonId);
	};
	return (
		<div>
			<div className="promocodeForPackage m-4 d-flex flex-wrap justify-content-center">
				<Input
					type={"number"}
					max={100}
					min={1}
					placeholder="Percent %"
					className="percentBox text-center m-2"
					value={percent}
					onChange={(e) => {
						updatePercent(e.target.value);
					}}
				/>
				<div className="my-2">
					<SelectSearch
						style={{ width: 200 }}
						options={options}
						search
						value={guruId}
						placeholder="Select a guru"
						filterOptions={fuzzySearch}
						onChange={(guruId) => {
							updateGuruId(guruId);
							handleGetPackages(guruId);
							updatePackageId(null);
						}}
					/>
				</div>

				<Select
					style={{ width: 200 }}
					onChange={handlePackageChange}
					className="m-2"
					disabled={!packagesList.length}
					placeholder={"Select a package"}>
					{packagesList.map((packagee) => {
						return (
							<Option value={packagee._id} key={packagee._id}>
								{packagee.name}
							</Option>
						);
					})}
				</Select>
				<Select
					style={{ width: 300 }}
					onChange={handleChangeSalesPerson}
					className="m-2"
					disabled={!packagesList.length}
					placeholder={"Select a sales person (Optional)"}>
					{salesPersonsData.map((person) => {
						return (
							<Option value={person.salesPersonId} key={person.salesPersonId}>
								{person.name}
							</Option>
						);
					})}
				</Select>
				<Input
					type={"text"}
					placeholder="Promocode (optional)"
					className="text-center m-2"
					value={promocode}
					style={{ width: "200px" }}
					onChange={(e) => {
						updatePromocode(e.target.value);
					}}
				/>

				<Button className="btn m-2" onClick={handleGeneratePromocode}>
					Generate
				</Button>
			</div>
		</div>
	);
}

export default PromocodeForPackage;
