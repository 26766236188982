import React, { useState } from "react";
import { Button, Form, Input, Modal } from "antd";
import { addEditPackage } from "../../network";
import { toastSuccess } from "../../../../helpers/toast";

function AddEditPackage({ isModalVisible, onCancel, guruId }) {
	const { TextArea } = Input;
	const [loading, updateLoading] = useState(false);
	const onFinish = (values) => {
		let data = {
			...values,
			coachId: guruId,
		};
		updateLoading(true);
		addEditPackage(
			data,
			(success) => {
				updateLoading(false);
				onCancel();
				toastSuccess(success.data.message);
			},
			(fail) => {
				updateLoading(false);
			}
		);
	};
	return (
		<Modal
			visible={isModalVisible?.status}
			onCancel={onCancel}
			footer={[]}
			className="addEditPackage">
			<Form
				layout={"vertical"}
				name="basic"
				initialValues={{ remember: true, ...isModalVisible?.data }}
				onFinish={onFinish}
				autoComplete="off"
				size="large">
				<Form.Item
					label="Title"
					name="name"
					rules={[
						{ required: true, message: "Please type the package title!" },
					]}>
					<Input />
				</Form.Item>
				<Form.Item
					label="Description"
					name="description"
					rules={[
						{ required: true, message: "Please type the package description!" },
					]}>
					<TextArea style={{ resize: "none", height: "200px" }} />
				</Form.Item>
				<Form.Item
					label="Sessions count"
					name="sessionCount"
					rules={[
						{
							required: true,
							message: "Please type a valid Sessions count!",
							validator: async (rule, value) => {
								const isNumber = /^[1-9]/.test(value);
								if (isNumber) {
									return;
								} else {
									throw new Error();
								}
							},
						},
					]}>
					<Input min="1" type="number" />
				</Form.Item>
				<Form.Item
					label="Price (USD)"
					name="price"
					rules={[
						{
							required: true,
							message: "Please type a valid package price!",
							validator: async (rule, value) => {
								const isNumber = /^[1-9]/.test(value);
								if (isNumber) {
									return;
								} else {
									throw new Error();
								}
							},
						},
					]}>
					<Input min="1" type="number" />
				</Form.Item>
				<Form.Item className="text-center">
					<Button
						className="secondaryBtn"
						loading={loading}
						type="primary"
						htmlType="submit">
						Submit
					</Button>
				</Form.Item>
			</Form>
		</Modal>
	);
}

export default AddEditPackage;
