import React from "react";
import Navbar from "../common/components/navbar/navbar";
import Header from "../common/components/header/header";
import TableHeader from "../common/components/tableHeader/tableHeader";
import AddEvent from "./Components/AddEvent/AddEvent";

function Events() {
	return (
		<div>
			<Navbar />
			<Header parent="Events" />
			<TableHeader parent={"events"} />
			<div className="my-3">
				<AddEvent />
			</div>
		</div>
	);
}

export default Events;
