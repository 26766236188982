import { Button, Table } from "antd";
import React, { useEffect, useState } from "react";
import SelectSearch, { fuzzySearch } from "react-select-search";
import { getAllUsers } from "../../../gurus/network";
import { deletePackage, getGurusPackages } from "../../network";
//import gear from "../../../../assets/icons/gear.svg";
import AddEditPackage from "../AddEditPackage/AddEditPackage";
import { toastSuccess } from "../../../../helpers/toast";
import "./ViewPackages.css";

function ViewPackages() {
	const [guruId, updateGuruId] = useState("1");
	const [options, updateOptions] = useState([]);
	const [isModalVisible, updateIsModalVisible] = useState(false);
	const [dataSource, updateDataSource] = useState([]);
	const [loadingState, updateLoadingState] = useState(false);

	// const actionList = (record) => {
	// 	return (
	// 		<Menu>
	// 			<Menu.Item
	// 				onClick={() => {
	// 					updateIsModalVisible({ status: true, data: record });
	// 				}}
	// 				key={"edit"}>
	// 				Edit
	// 			</Menu.Item>
	// 			<Menu.Item
	// 				onClick={() => {
	// 					deletePackageDetail(record._id);
	// 				}}
	// 				key={"delete"}
	// 				danger>
	// 				Delete
	// 			</Menu.Item>
	// 		</Menu>
	// 	);
	// };
	useEffect(() => {
		let data = [];
		getAllUsers(
			(success) => {
				success.users.forEach((user) => {
					data.push({ name: user.fullName, value: user._id });
				});
				updateOptions(data);
			},
			(fail) => {
				console.log(fail);
			}
		);
	}, []);

	useEffect(() => {
		if (guruId) {
			getPackageDetails();
		}
		// eslint-disable-next-line
	}, [guruId]);
	// const deletePackageDetail = (packageId) => {
	// 	let data = { packageId };
	// 	deletePackage(
	// 		data,
	// 		(success) => {
	// 			toastSuccess(success.message);
	// 			getPackageDetails();
	// 		},
	// 		(fail) => {}
	// 	);
	// };

	const getPackageDetails = () => {
		updateLoadingState(true);
		let data = {
			guruId,
		};
		getGurusPackages(
			data,
			(success) => {
				updateDataSource(success.data.coachPackages);
				updateLoadingState(false);
			},
			(fail) => {
				updateLoadingState(false);
			}
		);
	};
	const handleDeletePackage = (packageId) => {
		let data = {
			packageId,
		};
		deletePackage(
			data,
			(success) => {
				toastSuccess(success.data.message);
				getPackageDetails();
			},
			(fail) => {
				console.log(fail);
			}
		);
	};
	const columns = [
		{
			title: "Title",
			dataIndex: "name",
			key: "name",
			align: "center",
		},
		{
			title: "Description",
			dataIndex: "description",
			key: "description",
			align: "center",
		},
		{
			title: "Sessions Count",
			dataIndex: "sessionCount",
			key: "sessionCount",
			align: "center",
		},
		{
			title: "Price",
			dataIndex: "price",
			key: "price",
			align: "center",
		},
		{
			title: "Status",
			dataIndex: "isActive",
			key: "isActive",
			align: "center",
			render: (isActive, record) => {
				return (
					<div className="d-flex flex-1 justify-content-center">
						{/* <Dropdown
							overlay={() => {
								return actionList(record);
							}}>
							<figure className="gear-figure">
								<img src={gear} alt="gear" />
							</figure>
						</Dropdown> */}
						{/* <Switch
							checked={isActive}
							onChange={handleChangeStatus}
							checkedChildren="Deactivate"
							unCheckedChildren="Activate"
						/> */}
						<Button
							danger
							onClick={() => {
								handleDeletePackage(record._id);
							}}>
							Delete
						</Button>
					</div>
				);
			},
		},
	];
	return (
		<div className="view-packages">
			<div className="d-flex flex-1 justify-content-center flex-column align-items-center my-4">
				<h4>Select a Guru</h4>
				<div className="d-flex">
					<SelectSearch
						options={options}
						search={true}
						value={guruId}
						placeholder="Select a guru"
						filterOptions={fuzzySearch}
						onChange={(guruId) => {
							updateGuruId(guruId);
						}}
					/>
					{guruId && (
						<Button
							className="secondaryBtn mx-4"
							onClick={() => {
								updateIsModalVisible({ status: true });
							}}>
							Add New Package
						</Button>
					)}
				</div>
			</div>
			{guruId && (
				<Table
					dataSource={dataSource}
					columns={columns}
					scroll={{ x: 1000, y: 400 }}
					pagination={{ position: ["bottomCenter"] }}
					loading={loadingState}
				/>
			)}
			{isModalVisible && (
				<AddEditPackage
					isModalVisible={isModalVisible}
					onCancel={() => {
						updateIsModalVisible(null);
						getPackageDetails();
					}}
					guruId={guruId}
				/>
			)}
		</div>
	);
}

export default ViewPackages;
