import React, { Component } from "react";
import Navbar from "../common/components/navbar/navbar";
import ForgetPasswordForm from "./Components/ForgetPasswordForm/ForgetPasswordForm";
export default class ForgetPassword extends Component {
	render() {
		return (
			<div className="emailConfirmation">
				<Navbar hideMenu={true} />
				<div className="divider"></div>
				<div className="label">
					Password
					<br />
					Reset
				</div>
				<ForgetPasswordForm />
			</div>
		);
	}
}
