import React from "react";
import Header from "../common/components/header/header";
import Navbar from "../common/components/navbar/navbar";
import TableHeader from "../common/components/tableHeader/tableHeader";
import SalesGrid from "./Components/SalesGrid/SalesGrid";
import "./Sales.css";
function sales() {
	return (
		<div className="sales">
			<Navbar />
			<Header parent="Sales" />
			<TableHeader parent={"sales"} />
			<div className="my-3">
				<SalesGrid />
			</div>
		</div>
	);
}

export default sales;
