import { getResource, postResource } from "../../services";

export const GetAllReportedCoaches = (onSuccess, onFail) => {
	const path = "admin/GetAllReportedCoaches";
	getResource(path, onSuccess, onFail);
};
export const AdminAcceptOrDeclineComplain = (body, onSuccess, onFail) => {
	const path = "admin/AdminAcceptOrDeclineComplain";
	postResource(path, body, onSuccess, onFail);
};
