import React from "react";
import Routes from "./Routes";
import { logout } from "./network";
import { connect } from "react-redux";
import { useIdleTimer } from "react-idle-timer";
import { AuthActions } from "./Store/Actions/AuthActions";
import { ToastContainer } from "react-toastify";
import "react-notifications/lib/notifications.css";
import "antd/dist/antd.css";
import "@progress/kendo-theme-default/dist/all.css";
import "react-toastify/dist/ReactToastify.css";

export const isSummerTime = true;

function App(props) {
	const handleOnIdle = (event) => {
		if (props.auth.authData) {
			logout({ adminId: props.auth.authData._id }, (success) => {
				props.AuthActions(0);
			});
		}
	};

	const handleOnActive = (event) => {
		// console.log("user is active", event);
		// console.log("time remaining", getRemainingTime());
	};

	const handleOnAction = (event) => {
		// console.log("user did something", event);
	};
	// eslint-disable-next-line
	const { getRemainingTime, getLastActiveTime } = useIdleTimer({
		timeout: 1000 * 60 * 3,
		onIdle: handleOnIdle,
		onActive: handleOnActive,
		onAction: handleOnAction,
		debounce: 500,
	});
	return (
		<div className="App">
			<Routes />
			<ToastContainer />
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		AuthActions: (auth) => dispatch(AuthActions(auth)),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
