import React, { useState, useEffect } from "react";
import { Row, Col, Input, Button, Space } from "antd";
import Navbar from "../common/components/navbar/navbar";
import Header from "../common/components/header/header";
//import SideMenu from "../common/components/sideMenu/sideMenu";
import { getAllAdmins } from "./network";
import Grid from "../common/components/grid/grid";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import ConfirmDeleteModal from "./components/ConfirmDeleteModal/ConfirmDeleteModal";

function Admin() {
	const [adminsList, getAdminsList] = useState([]);
	const [searchText, setSearchText] = useState("");
	const [searchedColumn, setSearchedColumn] = useState("");
	const [isModalVisible, toggleModalVisible] = useState(false);
	const [deletedAdmin, updateDeletedAdmin] = useState(null);
	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	};

	const handleReset = (clearFilters) => {
		clearFilters();
		setSearchText("");
	};

	const getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({
			setSelectedKeys,
			selectedKeys,
			confirm,
			clearFilters,
		}) => (
			<div style={{ padding: 8 }}>
				<Input
					ref={(node) => {
						//			searchInput = node;
					}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) =>
						setSelectedKeys(e.target.value ? [e.target.value] : [])
					}
					//value={searchText[0]}
					onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ marginBottom: 8, display: "block" }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
						size="small"
						style={{ width: 90 }}
					>
						Search
					</Button>
					<Button
						onClick={() => handleReset(clearFilters)}
						size="small"
						style={{ width: 90 }}
					>
						Reset
					</Button>
				</Space>
			</div>
		),
		onFilter: (value, record) =>
			record[dataIndex]
				? record[dataIndex]
						.toString()
						.toLowerCase()
						.includes(value.toLowerCase())
				: "",
		onFilterDropdownVisibleChange: (visible) => {
			if (visible) {
				//	setTimeout(() => searchInput.select(), 100);
			}
		},
		render: (text) =>
			searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
					searchWords={[searchText]}
					autoEscape
					textToHighlight={text ? text.toString() : ""}
				/>
			) : (
				text
			),
	});

	useEffect(() => {
		getAllAdmins(
			(success) => getAdminsList(success),
			(fail) => {}
		);
	}, []);
	const columns = [
		{
			title: "Name",
			dataIndex: "fullName",
			key: "fullName",
			sorter: (a, b) => {
				if (a.fullName < b.fullName) return -1;
				else return 1;
			},
			...getColumnSearchProps("fullName"),
			filterIcon: (filtered) => (
				<SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
			),
		},
		{
			title: "Email",
			dataIndex: "email",
			key: "email",
			sorter: (a, b) => {
				if (a.email < b.email) return -1;
				else return 1;
			},
		},
		{
			title: "Phone Number",
			dataIndex: "phone",
			key: "phone",
			sorter: (a, b) => {
				if (a.phone < b.phone) return -1;
				else return 1;
			},
		},
		{
			title: "Actions",
			dataIndex: "actions",
			key: "actions",
			render: (action, _) => {
				return (
					<div className="text-center">
						<Button
							danger
							onClick={() => {
								updateDeletedAdmin(_);
								toggleModalVisible(!isModalVisible);
							}}
						>
							Delete
						</Button>
					</div>
				);
			},
		},
	];

	return (
		<div className="admins">
			<Navbar />
			<Header parent="Admins" />
			<Row>
				{/* <Col xs={4}>
					<SideMenu current="Admins" />
				</Col> */}
				<Col xs={24}>
					<Grid
						dataSource={adminsList}
						columns={columns}
						parent="Admins"
						refresh={(val) => {
							if (val) {
								getAllAdmins(
									(success) => getAdminsList(success),
									(fail) => {}
								);
							}
						}}
					/>
				</Col>
			</Row>
			{isModalVisible && (
				<ConfirmDeleteModal
					isModalVisible={isModalVisible}
					toggleModal={() => {
						toggleModalVisible(!isModalVisible);
						getAllAdmins(
							(success) => getAdminsList(success),
							(fail) => {}
						);
					}}
					deletedAdmin={deletedAdmin}
				/>
			)}
		</div>
	);
}

export default Admin;
