import { postResource } from "../../services";

export const uploadEventCover = (body, onSuccess, onFail) => {
	const path = "event/imageEventUpload ";
	postResource(path, body, onSuccess, onFail, true, true);
};

export const postEvent = (body, onSuccess, onFail) => {
	const path = "event";
	postResource(path, body, onSuccess, onFail);
};
