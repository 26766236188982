import React from "react";
import { Modal, Button } from "antd";
import { deleteAdmin } from "../../network";
import "./ConfirmDeleteModal.css";
function ConfirmDeleteModal({ isModalVisible, toggleModal, deletedAdmin }) {
	const DeleteAdmin = (adminId) => {
		let body = {
			Id: deletedAdmin._id,
		};
		deleteAdmin(
			body,
			(success) => {
				toggleModal();
			},
			(fail) => {}
		);
	};

	return (
		<Modal
			visible={isModalVisible}
			onCancel={toggleModal}
			footer={[]}
			width={"50%"}
			centered
			className="deleteAdminsModal"
		>
			<div className="text text-center">
				Are you sure you want to delete {deletedAdmin.fullName}?
			</div>
			<div className="btnContainer" style={{ marginTop: "30px" }}>
				<Button
					className="primaryBtn"
					onClick={() => {
						toggleModal();
					}}
				>
					Cancel
				</Button>
				<input
					type="submit"
					value="Confirm"
					className="secondaryBtn"
					onClick={DeleteAdmin}
				/>
			</div>
		</Modal>
	);
}

export default ConfirmDeleteModal;
