import React, { useEffect, useState } from "react";
import Navbar from "../common/components/navbar/navbar";
import Header from "../common/components/header/header";
import { Row, Col, Input, Button, Space } from "antd";
//import SideMenu from "../common/components/sideMenu/sideMenu";
import { GetAllReportedCoaches } from "./network";
import Grid from "../common/components/grid/grid";
import ReportedCoachModal from "./components/reportedCoachModal/reportedCoachModal";
import { mediaBaseUrl } from "../../services";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";

function ReportedCoaches() {
	const [reportedCoaches, setReportedCoaches] = useState([]);
	const [isModalVisible, toggleModal] = useState(false);
	const [record, setRecord] = useState(null);
	const [searchText, setSearchText] = useState("");
	const [searchedColumn, setSearchedColumn] = useState("");

	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	};

	const handleReset = (clearFilters) => {
		clearFilters();
		setSearchText("");
	};

	const getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({
			setSelectedKeys,
			selectedKeys,
			confirm,
			clearFilters,
		}) => (
			<div style={{ padding: 8 }}>
				<Input
					ref={(node) => {
						//			searchInput = node;
					}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) =>
						setSelectedKeys(e.target.value ? [e.target.value] : [])
					}
					//value={searchText[0]}
					onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ marginBottom: 8, display: "block" }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
						size="small"
						style={{ width: 90 }}>
						Search
					</Button>
					<Button
						onClick={() => handleReset(clearFilters)}
						size="small"
						style={{ width: 90 }}>
						Reset
					</Button>
				</Space>
			</div>
		),
		onFilter: (value, record) =>
			record[dataIndex]
				? record[dataIndex]
						.toString()
						.toLowerCase()
						.includes(value.toLowerCase())
				: "",
		onFilterDropdownVisibleChange: (visible) => {
			if (visible) {
				//	setTimeout(() => searchInput.select(), 100);
			}
		},
		render: (text) =>
			searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
					searchWords={[searchText]}
					autoEscape
					textToHighlight={text ? text.toString() : ""}
				/>
			) : (
				text
			),
	});
	const columns = [
		{
			title: "",
			dataIndex: "image",
			key: "image",
			render: (image) => {
				return (
					<div className="avatarContainer">
						<img src={mediaBaseUrl + image} alt="avatar" />
					</div>
				);
			},
			onCell: (record, rowIndex) => ({
				onClick: () => {
					setRecord(record);
					toggleModal(!isModalVisible);
				},
			}),
		},
		{
			title: "Guru Name",
			dataIndex: "fullName",
			key: "fullName",
			sorter: (a, b) => {
				if (a.guru < b.guru) return -1;
				else return 1;
			},
			onCell: (record, rowIndex) => ({
				onClick: () => {
					setRecord(record);
					toggleModal(!isModalVisible);
				},
			}),
			...getColumnSearchProps("fullName"),
			filterIcon: (filtered) => (
				<SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
			),
		},
		{
			title: "Client Name",
			dataIndex: "user",
			key: "user",
			sorter: (a, b) => {
				if (a[0] < b[0]) return -1;
				else return 1;
			},
			render: (user) => {
				if (user && user.length) return <div>{user[0].fullName}</div>;
			},
			onCell: (record, rowIndex) => ({
				onClick: () => {
					setRecord(record);
					toggleModal(!isModalVisible);
				},
			}),
		},
		{
			title: "Order Number",
			dataIndex: "session",
			key: "session",
			sorter: (a, b) => {
				if (a.orderNumber < b.orderNumber) return -1;
				else return 1;
			},
			render: (session) => {
				return <>{session[0]?._id}</>;
			},
		},
		{
			title: "Client Complain",
			dataIndex: "reviews",
			key: "reviews",
			sorter: (a, b) => {
				if (a.clientComplain < b.clientComplain) return -1;
				else return 1;
			},
			render: (reviews) => {
				return <>{reviews.report}</>;
			},
			onCell: (record, rowIndex) => ({
				onClick: () => {
					setRecord(record);
					toggleModal(!isModalVisible);
				},
			}),
		},
	];

	useEffect(() => {
		GetAllReportedCoaches(
			(success) => setReportedCoaches(success),
			(fail) => {}
		);
	}, []);

	return (
		<div>
			<Navbar />
			<Header parent="Reported Coaches" />
			<Row>
				{/* <Col xs={4}>
					<SideMenu current="Reported Coaches" />
				</Col> */}
				<Col xs={24}>
					<Grid
						dataSource={reportedCoaches}
						columns={columns}
						parent="ReportedCoaches"
					/>
				</Col>
			</Row>
			<ReportedCoachModal
				isModalVisible={isModalVisible}
				toggleModal={() => toggleModal(!isModalVisible)}
				record={record}
				refresh={(val) => {
					if (val) {
						GetAllReportedCoaches(
							(success) => setReportedCoaches(success),
							(fail) => {}
						);
					}
				}}
			/>
		</div>
	);
}

export default ReportedCoaches;
