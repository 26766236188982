import { combineReducers } from "redux";
import AuthReducer from "./AuthReducer";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import hardSet from "redux-persist/lib/stateReconciler/hardSet";
const persistConfig = {
	key: "root",
	storage,
	stateReconciler: hardSet,
};

const RootReducers = combineReducers({
	auth: AuthReducer,
});
export default persistReducer(persistConfig, RootReducers);
