import React, { Component } from "react";
import { Row, Col } from "antd";
import ChevronRight from "../../../../assets/icons/chevronRight.svg";
import ChevronLeft from "../../../../assets/icons/chevronLeft.svg";
import "./calendarHeader.css";
export class calendarHeader extends Component {
	state = {
		min: 0,
		max: 5,
		Dates: [],
		fullDates: [],
	};
	componentDidMount() {
		const monthNameArray = [
			"Jan",
			"Feb",
			"Mar",
			"Apr",
			"May",
			"Jun",
			"Jul",
			"Aug",
			"Sep",
			"Oct",
			"Nov",
			"Dec",
		];
		var currentDateObject = new Date(); // Now
		var Dates = [];
		var DaysIndex = 0;
		currentDateObject.setDate(currentDateObject.getDate());
		var currentDateStr = JSON.stringify(
			new Date(
				new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000)
			)
		);
		Dates.push({
			fullDate: currentDateStr.substr(1, 10),
			dayName: currentDateObject.toDateString().substr(0, 3),
			dayNumber: currentDateStr.substr(9, 2),
			Month: monthNameArray[parseInt(currentDateStr.substr(6, 2) - 1)],
		});

		for (let i = 0; i < 90; i++) {
			currentDateObject.setDate(currentDateObject.getDate() + 1);
			currentDateStr = JSON.stringify(
				new Date(
					currentDateObject.getTime() -
						currentDateObject.getTimezoneOffset() * 60000
				)
			);

			Dates.push({
				fullDate: currentDateStr.substr(1, 10),
				dayName: currentDateObject.toDateString().substr(0, 3),
				dayNumber: currentDateStr.substr(9, 2),
				Month: monthNameArray[parseInt(currentDateStr.substr(6, 2) - 1)],
			});
			if (DaysIndex >= 6) {
				DaysIndex = 0;
			}
			DaysIndex++;
		}
		let currentDatesArr = [];
		for (let i = this.state.min; i < this.state.max; i++) {
			currentDatesArr.push(Dates[i].fullDate);
		}
		this.props.getCurrentPages(currentDatesArr);
		this.setState({ Dates });
	}
	render(props) {
		let currentDatesArr = [];
		return (
			<div className="calendarHeader scroll">
				<div className="calendarHeaderContainer ">
					<div className="calendarHeaderTitle ">
						<Row>
							<Col xs={2}>
								<div
									className="CalendarControls"
									onClick={() => {
										if (!(this.state.min === 0)) {
											this.setState(
												{
													min: this.state.min - 5,
													max: this.state.max - 5,
												},
												() => {
													for (
														let i = this.state.min;
														i < this.state.max;
														i++
													) {
														currentDatesArr.push(this.state.Dates[i].fullDate);
													}
													this.props.getCurrentPages(currentDatesArr);
													this.props.handleCalendarPages(this.state.min);
												}
											);
										}
									}}>
									<img src={ChevronLeft} alt="ChevronLeft" />
								</div>
							</Col>
							{this.state.Dates.map((date, i) => {
								return (
									i >= this.state.min &&
									i < this.state.max && (
										<Col key={i} xs={4}>
											<div>
												<div>{date.dayName}</div>
												<span>
													{date.Month} {date.dayNumber}
												</span>
											</div>
										</Col>
									)
								);
							})}

							<Col>
								<div
									className="CalendarControls"
									onClick={() => {
										if (!(this.state.max > 85)) {
											this.setState(
												{
													min: this.state.min + 5,
													max: this.state.max + 5,
												},
												() => {
													this.props.handleCalendarPages(this.state.min);
													for (
														let i = this.state.min;
														i < this.state.max;
														i++
													) {
														currentDatesArr.push(this.state.Dates[i].fullDate);
													}
													this.props.getCurrentPages(currentDatesArr);
													this.props.handleCalendarPages(this.state.min);
												}
											);
										}
									}}>
									<img src={ChevronRight} alt="ChevronRight" />
								</div>
							</Col>
						</Row>
					</div>
				</div>
			</div>
		);
	}
}

export default calendarHeader;
