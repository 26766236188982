import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Gurus from "./modules/gurus/gurus";
import Clients from "./modules/clients/clients";
import Sessions from "./modules/sessions/sessions";
import ReportedCoaches from "./modules/reportedCoaches/reportedCoaches";
import ContactMessages from "./modules/contactMessages/contactMessages";
import NewAccountRequests from "./modules/newAccountRequests/newAccountRequests";
import Dashboard from "./modules/dashboard/dashboard";
import WebsiteWords from "./modules/websiteWords/websiteWords";
import LiveChat from "./modules/liveChat/liveChat";
import ForgetPassword from "./modules/ForgetPassword/ForgetPassword";
import Login from "./modules/login/login";
import ChangePassword from "./modules/ChangePassword/ChangePassword";
import Discounts from "./modules/Discounts/Discounts";
import { connect } from "react-redux";
import Admins from "./modules/admins/admins";
import Promocodes from "./modules/Promocodes/Promocodes";
import Payments from "./modules/Payments/Payments";
import Packages from "./modules/Packages/Packages";
import Sales from "./modules/sales/Sales";
import Events from "./modules/Events/Events";

function Routes(props) {
	const isAuth = props.auth.authData ? true : false;

	return (
		<Route
			render={({ location }) => (
				<Switch location={location}>
					<Route
						exact
						path="/"
						render={() => (isAuth ? <Redirect to="/sessions" /> : <Login />)}
					/>
					<Route
						path="/forgetPassword"
						render={() =>
							isAuth ? <Redirect to="/sessions" /> : <ForgetPassword />
						}
					/>
					<Route
						path="/discounts"
						render={() => (isAuth ? <Discounts /> : <Redirect to="/" />)}
					/>
					<Route
						path="/changePassword/:id"
						render={(props) =>
							isAuth ? (
								<Redirect to="/sessions" />
							) : (
								<ChangePassword {...props} />
							)
						}
					/>
					<Route
						exact
						path="/dashboard"
						render={() => (isAuth ? <Dashboard /> : <Redirect to="/" />)}
					/>
					<Route
						path="/newaccountrequests"
						render={() =>
							isAuth ? <NewAccountRequests /> : <Redirect to="/" />
						}
					/>
					<Route
						path="/gurus"
						render={() => (isAuth ? <Gurus /> : <Redirect to="/" />)}
					/>
					<Route
						path="/clients"
						render={() => (isAuth ? <Clients /> : <Redirect to="/" />)}
					/>
					<Route
						path="/reportedcoaches"
						render={() => (isAuth ? <ReportedCoaches /> : <Redirect to="/" />)}
					/>
					<Route
						path="/contactmessages"
						render={() => (isAuth ? <ContactMessages /> : <Redirect to="/" />)}
					/>
					<Route
						path="/sessions"
						render={() => (isAuth ? <Sessions /> : <Redirect to="/" />)}
					/>
					<Route
						path="/websitewords"
						render={() => (isAuth ? <WebsiteWords /> : <Redirect to="/" />)}
					/>
					<Route
						path="/liveChat"
						render={(props) =>
							isAuth ? <LiveChat {...props} /> : <Redirect to="/" />
						}
					/>
					<Route
						path="/admins"
						render={() => (isAuth ? <Admins /> : <Redirect to="/" />)}
					/>
					<Route
						path="/promocodes"
						render={() => (isAuth ? <Promocodes /> : <Redirect to="/" />)}
					/>
					<Route
						path="/payments"
						render={() => (isAuth ? <Payments /> : <Redirect to="/" />)}
					/>
					<Route
						path="/packages"
						render={() => (isAuth ? <Packages /> : <Redirect to="/" />)}
					/>

					<Route
						path="/sales"
						render={() => (isAuth ? <Sales /> : <Redirect to="/" />)}
					/>
					<Route
						path="/events"
						render={() => (isAuth ? <Events /> : <Redirect to="/" />)}
					/>
				</Switch>
			)}
		/>
	);
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	};
};

export default connect(mapStateToProps)(Routes);
