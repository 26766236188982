import React, { useState } from "react";
import { Modal, Col, Row, Button } from "antd";
import moment from "moment";
import { ContactAdminResponse } from "../../network";
import clock from "../../../../assets/icons/clock.svg";
import "./contactMessagesModal.css";

function ContactMessagesModal({
	isModalVisible,
	toggleModal,
	record,
	refresh,
}) {
	const [response, setResponse] = useState("");
	const handleCancel = () => {
		toggleModal();
	};
	const handelSubmit = () => {
		let body = {
			responseMessage: response,
			id: record._id,
		};
		ContactAdminResponse(
			body,
			(success) => {
				if (success.code === 1) {
					setResponse("");
					toggleModal();
					refresh(true);
				}
			},
			(fail) => {}
		);
	};
	return (
		<Modal
			visible={isModalVisible}
			onCancel={toggleModal}
			footer={[]}
			width={"90%"}
			centered
			className="contactMessagesModal"
		>
			<Row>
				<Col xs={24} md={12}>
					<div className="clientContactBox">
						<div>
							<div>{record.name}</div>
							<div>{record.email}</div>
							<div>{record.mobile}</div>
							<p>{record.message}</p>
						</div>
						<div className="divider"></div>
						<div className="timeBar">
							<span>
								<img src={clock} alt="clock" />
								<span>
									{moment(record.createdAt).format("DD/MM/YYYY , h:mm:ss a")}
								</span>
							</span>
						</div>
					</div>
				</Col>
				<Col xs={24} md={12}>
					<div className="adminResponseBox">
						<h6>Admin Response</h6>
						{record.adminReliedmessage &&
						record.adminReliedmessage.length > 0 ? (
							<p>{record.adminReliedmessage}</p>
						) : (
							<textarea
								value={response}
								onChange={(e) => setResponse(e.target.value)}
							/>
						)}
					</div>
				</Col>
			</Row>
			<div className="btnContainer">
				<Button className="primaryBtn" onClick={() => handleCancel()}>
					Cancel
				</Button>
				<Button className="secondaryBtn" onClick={() => handelSubmit()}>
					Submit
				</Button>
			</div>
		</Modal>
	);
}

export default ContactMessagesModal;
