import React, { useState } from "react";
import { Button, Form, Input } from "antd";
import { addSalesPerson } from "../../network";
import { toastError, toastSuccess } from "../../../../helpers/toast";
import { useHistory } from "react-router-dom";

function AddSalesPerson() {
	const [loading, updateLoading] = useState(false);
	const history = useHistory();
	const onFinish = ({ name }) => {
		updateLoading(true);
		let data = {
			name,
		};
		addSalesPerson(
			data,
			(success) => {
				updateLoading(false);
				toastSuccess(success.message);
				history.push("/sales");
			},
			(fail) => {
				updateLoading(false);
				toastError(fail.response.message);
			}
		);
	};
	return (
		<div className="addSalesPerson">
			<Form
				layout={"vertical"}
				name="basic"
				onFinish={onFinish}
				autoComplete="off"
				size="large">
				<Form.Item
					label="Name"
					name="name"
					rules={[{ required: true, message: "Please type the name!" }]}>
					<Input />
				</Form.Item>
				<Form.Item className="text-center">
					<Button
						className="secondaryBtn"
						loading={loading}
						type="primary"
						htmlType="submit">
						Submit
					</Button>
				</Form.Item>
			</Form>
		</div>
	);
}

export default AddSalesPerson;
