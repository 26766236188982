import React, { useEffect, useState } from "react";
import { Row, Col, Button, Space, Input } from "antd";
import Navbar from "../common/components/navbar/navbar";
import Header from "../common/components/header/header";
//import SideMenu from "../common/components/sideMenu/sideMenu";
import NewAccountModal from "./components/newAccountModal/newAccountModal";
import Grid from "../common/components/grid/grid";
import { GetAllPendingCoaches } from "./network";
import { mediaBaseUrl } from "../../services";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import moment from "moment";

function NewAccountRequests() {
	const [coaches, setCoaches] = useState([]);
	const [isModalVisible, toggleModal] = useState(false);
	const [record, setRecord] = useState(null);
	const [searchText, setSearchText] = useState("");
	const [searchedColumn, setSearchedColumn] = useState("");

	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	};

	const handleReset = (clearFilters) => {
		clearFilters();
		setSearchText("");
	};

	const getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({
			setSelectedKeys,
			selectedKeys,
			confirm,
			clearFilters,
		}) => (
			<div style={{ padding: 8 }}>
				<Input
					ref={(node) => {
						//			searchInput = node;
					}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) =>
						setSelectedKeys(e.target.value ? [e.target.value] : [])
					}
					//value={searchText[0]}
					onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ marginBottom: 8, display: "block" }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
						size="small"
						style={{ width: 90 }}>
						Search
					</Button>
					<Button
						onClick={() => handleReset(clearFilters)}
						size="small"
						style={{ width: 90 }}>
						Reset
					</Button>
				</Space>
			</div>
		),
		onFilter: (value, record) =>
			record[dataIndex]
				? record[dataIndex]
						.toString()
						.toLowerCase()
						.includes(value.toLowerCase())
				: "",
		onFilterDropdownVisibleChange: (visible) => {
			if (visible) {
				//	setTimeout(() => searchInput.select(), 100);
			}
		},
		render: (text) =>
			searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
					searchWords={[searchText]}
					autoEscape
					textToHighlight={text ? text.toString() : ""}
				/>
			) : (
				text
			),
	});

	const columns = [
		{
			title: "",
			dataIndex: "profileImg",
			key: "profileImg",
			render: (profileImg) => {
				return (
					<div className="avatarContainer">
						<img src={mediaBaseUrl + profileImg} alt="avatar" />
					</div>
				);
			},
			onCell: (record, rowIndex) => ({
				onClick: () => {
					setRecord(record);
					toggleModal(!isModalVisible);
				},
			}),
		},
		{
			title: "Guru Name",
			dataIndex: "guruName",
			key: "guruName",
			sorter: (a, b) => {
				if (a.guruName < b.guruName) return -1;
				else return 1;
			},
			onCell: (record, rowIndex) => ({
				onClick: () => {
					setRecord(record);
					toggleModal(!isModalVisible);
				},
			}),
			...getColumnSearchProps("guruName"),
			filterIcon: (filtered) => (
				<SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
			),
		},
		{
			title: "Year Of Birth",
			dataIndex: "dateOfBirth",
			key: "dateOfBirth",
			sorter: (a, b) =>
				new Date(a.dateOfBirth).getTime() - new Date(b.dateOfBirth).getTime(),
			onCell: (record, rowIndex) => ({
				onClick: () => {
					setRecord(record);
					toggleModal(!isModalVisible);
				},
			}),
			render: (dateOfBirth) => {
				return <div>{moment(dateOfBirth).format("YYYY")}</div>;
			},
		},

		{
			title: "Email",
			dataIndex: "email",
			key: "email",
			sorter: (a, b) => {
				if (a.email < b.email) return -1;
				else return 1;
			},
			onCell: (record, rowIndex) => ({
				onClick: () => {
					setRecord(record);
					toggleModal(!isModalVisible);
				},
			}),
		},
		{
			title: "Phone Number",
			dataIndex: "phoneNumber",
			key: "phoneNumber",
			sorter: (a, b) => {
				if (a.phoneNumber < b.phoneNumber) return -1;
				else return 1;
			},
			onCell: (record, rowIndex) => ({
				onClick: () => {
					setRecord(record);
					toggleModal(!isModalVisible);
				},
			}),
		},
	];

	useEffect(() => {
		GetAllPendingCoaches(
			(success) => {
				setCoaches(success.coaches);
			},
			(fail) => {}
		);
	}, []);

	return (
		<div className="newAccountRequests">
			<Navbar />
			<Header parent="New Account Requests" />
			<Row>
				{/* <Col xs={4}>
					<SideMenu current="New Account Requests" />
				</Col> */}
				<Col xs={24}>
					<Grid
						dataSource={coaches}
						columns={columns}
						parent="NewAccountRequests"
					/>
				</Col>
			</Row>
			{record && (
				<NewAccountModal
					isModalVisible={isModalVisible}
					toggleModal={() => toggleModal(!isModalVisible)}
					record={record}
					refresh={(val) => {
						if (val)
							GetAllPendingCoaches(
								(success) => {
									setCoaches(success.coaches);
								},
								(fail) => {}
							);
					}}
				/>
			)}
		</div>
	);
}

export default NewAccountRequests;
