import { toast } from "react-toastify";

const props = {
	position: "bottom-right",
	autoClose: 5000,
	rtl: true,
};

export const toastSuccess = (message) => {
	toast.success(message, props);
};

export const toastError = (message) => {
	if (message) {
		toast.error(message, props);
	} else {
		toast.error("An Error Occured", props);
	}
};
