import React, { useEffect, useState } from "react";
import { Table } from "antd";
import TableHeader from "../tableHeader/tableHeader";
import LiveChatModal from "../../../liveChat/components/liveChatModal/liveChatModal";
import { Excel } from "antd-table-saveas-excel";
import "./grid.css";

function Grid(props) {
	const [isModalVisible, toggleModal] = useState(false);
	const [record, setRecord] = useState({});
	const [isMsgSeen, updateMsgSeen] = useState(false);
	useEffect(() => {
		if (
			props.parent === "LiveChat" &&
			!isMsgSeen &&
			props.dataSource.length &&
			props.state &&
			props.state.opensChat
		) {
			let sortedByDate = props.dataSource.sort(function (a, b) {
				// Turn your strings into dates, and then subtract them
				// to get a value that is either negative, positive, or zero.
				return new Date(b.lastDate) - new Date(a.lastDate);
			});

			setRecord(sortedByDate[0]);
			toggleModal(!isModalVisible);
			updateMsgSeen(true);
		}
		// eslint-disable-next-line
	}, [props.dataSource]);

	const excel = new Excel();

	const handleSaveExcel = () => {
		let allCols = [...props.columns];
		let allData = [...props.dataSource];

		allCols.forEach((colItem, colIndex) => {
			if (props.parent !== "ReportedCoaches") {
				delete colItem.render;
			}
			if (colItem.key === "isActive") {
				allCols[colIndex].title = "isActive";
			}
		});
		allCols = allCols.filter(
			(colItem) =>
				colItem.key !== "actions" &&
				colItem.title.length > 0 &&
				colItem.key !== "documents" &&
				colItem.key !== "response" &&
				colItem.key !== "personalIdimage"
		);
		excel
			.addSheet(`${props.parent}`)
			.addColumns(allCols)
			.addDataSource(allData)
			.saveAs(`${props.parent}.xlsx`);
	};
	return (
		<>
			<TableHeader
				parent={props.parent}
				refresh={(val) => {
					props.refresh(val);
				}}
				searchValueFun={(searchValue) => {
					props.searchValue(searchValue);
				}}
				newMsgNotification={props.newMsgNotification}
			/>
			<div className="text-right">
				<button className="secondaryBtn m-4" onClick={handleSaveExcel}>
					Export
				</button>
			</div>

			<Table
				dataSource={props.dataSource}
				columns={props.columns}
				onChange={props.onChange}
				scroll={{ x: 1000, y: 400 }}
				onRow={(record) => {
					//onRow clicking event for the rows that only requried toggling th modal on the entire row
					//onCell events for the rows that contains action sumbitters that never required to toggle the modal on
					return {
						onClick: (event) => {
							setRecord(record);
							toggleModal(!isModalVisible);
						},
					};
				}}
				pagination={{ position: ["bottomCenter"] }}
				loading={props.loading}
			/>
			<div>
				{props.parent === "LiveChat" && isModalVisible && (
					<LiveChatModal
						isModalVisible={isModalVisible}
						toggleModal={() => {
							toggleModal(!isModalVisible);
						}}
						record={record}
					/>
				)}
			</div>
		</>
	);
}

export default Grid;
