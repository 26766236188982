import { getResource, postResource } from "../../services";

export const addSalesPerson = (body, onSuccess, onFail) => {
	const path = "salesPerson/addSalesPerson";
	postResource(path, body, onSuccess, onFail);
};

export const getSalesPersonData = (onSuccess, onFail) => {
	const path = "salesPerson/salesPersonPromoCodes";
	getResource(path, onSuccess, onFail);
};
