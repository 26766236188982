import React from "react";
import { Row, Col } from "antd";
import LogoPage from "./components/logoPage/logoPage";
import LoginForm from "./components/loginForm/loginForm";
function Login() {
	return (
		<div className="login">
			<Row>
				<Col xs={24} md={8}>
					<LogoPage />
				</Col>
				<Col xs={24} md={16}>
					<LoginForm />
				</Col>
			</Row>
		</div>
	);
}

export default Login;
