import { postResource, getResource, deleteResource } from "../../services";

export const AddAdmin = (body, onSuccess, onFail) => {
	const path = "admin/AddAdmin";
	postResource(path, body, onSuccess, onFail);
};
export const getAllAdmins = (onSuccess, onFail) => {
	const path = "admin/GetAllAdmins";
	getResource(path, onSuccess, onFail);
};
export const deleteAdmin = (body, onSuccess, onFail) => {
	const path = "admin/deleteAdmin";
	deleteResource(body, path, onSuccess, onFail);
};
