import React from "react";
import Header from "../common/components/header/header";
import Navbar from "../common/components/navbar/navbar";
import TableHeader from "../common/components/tableHeader/tableHeader";
import ViewPackages from "./Components/ViewPackages/ViewPackages";
import "./Packages.css";
function Packages() {
	return (
		<div>
			<Navbar />
			<Header parent="Packages" />
			<TableHeader parent={"packages"} />
			<div className="my-3">
				<ViewPackages />
			</div>
		</div>
	);
}

export default Packages;
