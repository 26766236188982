import React, { useEffect, useState } from "react";
import {
	Button,
	DatePicker,
	Form,
	Input,
	InputNumber,
	Radio,
	Select,
	Spin,
	Upload,
} from "antd";
import { mediaBaseUrl } from "../../../../services";
import uploadImg from "../../../../assets/icons/uploadPhoto.svg";
import { getAllUsers } from "../../../gurus/network";
import { postEvent, uploadEventCover } from "../../network";
import { toastSuccess } from "../../../../helpers/toast";
import moment from "moment";
import "./AddEvent.css";

function AddEvent() {
	const [imageURL, updateImageURL] = useState(null);
	const [gurusList, updateGurusList] = useState([]);
	const [loading, updateLoading] = useState(false);
	const [guruId, updateGuruId] = useState(null);
	const [dates, updateDate] = useState([
		{
			fullDate: "",
			times: [],
		},
	]);
	const { RangePicker } = DatePicker;
	const { TextArea } = Input;
	const [form] = Form.useForm();

	useEffect(() => {
		getAllUsers(
			(success) => {
				let allGurus = [];
				allGurus = success.users.map((user) => {
					return { value: user._id, label: user.fullName };
				});
				updateGurusList(allGurus);
			},
			(fail) => {}
		);
	}, []);

	const handleUploadCover = (e) => {
		const file = e.file;
		if (file.type.includes("image")) {
			let imageFormData = new FormData();
			imageFormData.append("imageEvent", file);
			updateLoading(true);
			uploadEventCover(
				imageFormData,
				(success) => {
					updateLoading(false);
					updateImageURL(success.path);
				},
				(fail) => {
					updateLoading(false);
				}
			);
		}
	};
	const handleChooseGuru = (guruId) => {
		updateGuruId(guruId);
	};
	const handleReset = () => {
		form.resetFields();
		updateImageURL(null);
	};
	const onFinish = (values) => {
		const data = {
			...values,
			guruId,
			image: imageURL,
			status: values.status ? values.status : "online",
		};

		const dataKeys = Object.keys(data);
		const datesKeys = dataKeys
			.map((key) => {
				var returnedKey = null;
				if (key.includes("date")) {
					returnedKey = key;
				}
				return returnedKey;
			})
			.filter((date) => date);

		const isDateAddedBefore = (addedDate) =>
			eventDates.findIndex(
				(date) => date.fullDate === moment(addedDate).format("DD-MM-YYYY")
			);
		const eventDates = [];
		datesKeys.forEach((key) => {
			if (isDateAddedBefore(data[key][0]) !== -1) {
				eventDates[isDateAddedBefore(data[key][0])].times.push({
					startDate: new Date(data[key][0]).toISOString(),
					endDate: new Date(data[key][1]).toISOString(),
				});
			} else {
				eventDates.push({
					fullDate: moment(data[key][0]).format("DD-MM-YYYY"),
					times: [
						{
							startDate: new Date(data[key][0]).toISOString(),
							endDate: new Date(data[key][1]).toISOString(),
						},
					],
				});
			}
		});
		data.eventDates = eventDates;
		updateLoading(true);
		postEvent(
			data,
			(success) => {
				updateLoading(false);
				toastSuccess(success.message);
				handleReset();
			},
			(fail) => {
				updateLoading(false);
			}
		);
	};
	const beforeUpload = (file) => {
		// Rename the file
		const newFileName = "new-file-name.jpg";
		file.name = newFileName;

		// Return the modified file
		return file;
	};

	const handleAddNewDateSlot = () => {
		let eventDates = [...dates];
		eventDates.push({
			fullDate: "",
			times: [],
		});
		updateDate(eventDates);
	};
	const options = [
		{
			label: "Online",
			value: "online",
		},
		{
			label: "Offline",
			value: "offline",
		},
	];
	const preventLetters = (e) => {
		if (!/[0-9]/.test(e.key)) {
			e.preventDefault();
		}
	};
	const spinnerStyle = {
		width: "150px",
		height: "150px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	};
	return (
		<div className="d-flex flex-column justify-content-center m-auto">
			<Form
				form={form}
				name="basic"
				onFinish={onFinish}
				requiredMark={false}
				colon={false}
				layout="vertical"
				className="add-event d-flex flex-column justify-content-center align-items-center">
				<Form.Item
					label=""
					name="imagee"
					rules={[
						{
							required: true,
							message: (
								<div className="text-center">
									Please Upload The Article Thumbnail!
								</div>
							),
						},
						() => ({
							validator(_, value) {
								if (!value) {
									return Promise.reject();
								} else if (!value.file && value?.includes("jpg")) {
									return Promise.resolve();
								} else if (value?.file?.type.includes("image")) {
									return Promise.resolve();
								}
								return Promise.reject(
									<div className="text-center">Please Upload Images Only</div>
								);
							},
						}),
					]}>
					<Upload
						disabled={loading}
						action="/upload.do"
						accept="image"
						onChange={handleUploadCover}
						beforeUpload={beforeUpload}
						className="m-auto">
						{loading ? (
							<Spin spinning={loading} style={spinnerStyle} />
						) : imageURL ? (
							<div className="uploadedImg-container">
								<img src={mediaBaseUrl + imageURL} alt="uploadImg" />
							</div>
						) : (
							<figure className="thumbnail-figure mt-3">
								<div className="uploadImg-container">
									<img src={uploadImg} alt="uploadImg" />
								</div>
							</figure>
						)}
					</Upload>
				</Form.Item>
				<Form.Item
					label="Guru Name"
					name="guruId"
					rules={[{ required: true, message: "Please select a guru!" }]}>
					<Select
						placeholder="Select a Guru"
						onChange={handleChooseGuru}
						options={gurusList}
					/>
				</Form.Item>
				<Form.Item
					label="Event Name"
					name="name"
					rules={[
						{ required: true, message: "Please, Enter the event name!" },
					]}>
					<Input />
				</Form.Item>
				<Form.Item
					label="Price"
					name="cost"
					rules={[{ required: true, message: "Please, Enter the price!" }]}>
					<InputNumber onKeyPress={preventLetters} placeholder="$120" />
				</Form.Item>
				<Form.Item label="Number of seats" name="noOfSeats">
					<InputNumber onKeyPress={preventLetters} placeholder="10 Seats" />
				</Form.Item>
				<Form.Item label="Location" name="location">
					<Input placeholder="Street 15" />
				</Form.Item>
				<Form.Item defaultValue={"online"} label="Status" name="status">
					<Radio.Group defaultValue={"online"}>
						{options.map((option) => {
							return <Radio value={option.value}>{option.label}</Radio>;
						})}
					</Radio.Group>
				</Form.Item>
				{dates.map((date, index) => {
					return (
						<Form.Item
							label={index === 0 ? "Time" : ""}
							name={`date${index}`}
							rules={[
								{ required: true, message: "Please select a Date time!" },
							]}>
							<RangePicker showTime format="YYYY/MM/DD hh:mm" />
						</Form.Item>
					);
				})}

				<div
					className="f-14 mainColor cursorPointer ant-select d-flex justify-content-end"
					onClick={handleAddNewDateSlot}>
					Add New Date
				</div>
				<Form.Item
					label="Desciption"
					name="description"
					rules={[{ required: true, message: "Please,Enter the desciption!" }]}>
					<TextArea style={{ resize: "none", height: "100px" }} />
				</Form.Item>

				<div className="text-center my-4">
					<Button
						className="primaryBtn mx-2 my-4"
						onClick={handleReset}
						loading={loading}>
						Discard
					</Button>
					<Button
						className="secondaryBtn mx-2 my-4"
						type="primary"
						loading={loading}
						htmlType="submit">
						Save
					</Button>
				</div>
			</Form>
		</div>
	);
}

export default AddEvent;
